<template>
  <div>
    <v-dialog
      v-model="open"
      transition="scale-transition"
      persistent
      scrollable
      fullscreen
    >
      <v-card flat>
        <v-card-title>
          {{
            !isEdit && remedy
              ? "Detail"
              : view && !remedy
              ? "Edit"
              : continuation
              ? $t("e_test.continuation")
              : $t("app.add")
          }}
          {{ remedy ? $t("e_test.remedy") : "E-Test" }}
          <div style="position: absolute; right: 25px;">
            <v-icon @click="closeDialog">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="mt-2">
          <v-stepper v-model="stepPosition" class="elevation-0">
            <hr />
            <v-stepper-header class="elevation-0">
              <v-stepper-step :complete="stepPosition > 1" step="1">
                <span>{{ $t("e_test.spesification") }}</span>
              </v-stepper-step>
              <v-divider />
              <v-stepper-step :complete="stepPosition > 2" step="2">
                <span>{{ $t("e_test.showed") }}</span>
              </v-stepper-step>
            </v-stepper-header>
            <hr />

            <v-stepper-items>
              <v-stepper-content step="1" class="stepper-content">
                <StepOne
                  :info="info"
                  :data="bodyRequest"
                  :reset="resetStepOne"
                  :validate="validateStepOne"
                  :isEdit="isEdit"
                  :dateRemedy="dateRemedy"
                  :remedy="remedy"
                  :continuation="continuation"
                />
              </v-stepper-content>

              <v-stepper-content step="2" class="stepper-content">
                <StepTwo
                  v-if="!remedy && !continuation"
                  :info="info"
                  :dataClass="classList"
                  :dataRequest="bodyRequest"
                  :isEdit="isEdit"
                  :reset="resetStepTwo"
                  @onChangeSelect="changeSelect"
                />
                <StepThree
                  v-else
                  :dataStudents="dataStudents"
                  :bodyRequest="bodyRequest"
                  :isEdit="isEdit"
                  :continuation="continuation"
                  @change="changeFilterValue"
                  @selectAll="selectAllStudent"
                  @search="searchStudent"
                  @pagination="paginateStudent"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-divider class="my-3" />
        <v-card-actions>
          <v-row no-gutters justify="end">
            <div v-if="showButtonEdit()">
              <v-btn
                v-if="isEdit"
                outlined
                class="gradient-error"
                dark
                @click="isEdit = false"
              >
                {{ $t("e_test.cancel_edit") }}
              </v-btn>
              <v-btn
                v-else
                outlined
                class="gradient-primary-dark"
                dark
                @click="isEdit = true"
              >
                {{ $t("app.edit") }}
              </v-btn>
            </div>
            <v-spacer />
            <div v-if="stepPosition === 1" class="d-flex">
              <v-btn
                v-if="isPublish && isEdit"
                :loading="loadingSave"
                color="gradient-primary"
                depressed
                dark
                @click="isEdit ? save() : closeDialog()"
              >
                {{ isEdit ? $t("app.save") : $t("app.done") }}
              </v-btn>
              <div v-else>
                <v-btn
                  outlined
                  dark
                  color="primary"
                  class="mr-2"
                  @click="closeDialog"
                >
                  {{ $t("app.cancel") }}
                </v-btn>
                <v-btn
                  color="gradient-primary"
                  depressed
                  dark
                  @click="nextStep(1)"
                >
                  {{ $t("app.next") }}
                </v-btn>
              </div>
            </div>
            <div v-else>
              <div style="float: right">
                <v-btn
                  outlined
                  dark
                  color="primary"
                  class="mr-2"
                  @click="prevStep(2)"
                >
                  {{ $t("app.back") }}
                </v-btn>
                <v-btn
                  :loading="loadingSave"
                  color="gradient-primary"
                  depressed
                  dark
                  @click="isEdit ? save() : closeDialog()"
                >
                  {{ isEdit ? $t("app.save") : $t("app.done") }}
                </v-btn>
              </div>
            </div>
          </v-row>
        </v-card-actions>
        <ModalConfirm
          :close="() => (this.modelDialogConfirm = false)"
          :loading="loadingConfirm"
          :isOpen="modelDialogConfirm"
          :save="saveDialog"
        >
          {{
            $t(
              continuation
                ? "e_test.msg.confirm_continuation"
                : "e_test.confirm_update_etest"
            )
          }}
        </ModalConfirm>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get_class_list } from "@/api/admin/schoolClass";
import {
  createEtest,
  getQuestionByID,
  listStudentScore,
  createRemedy,
  getStudent,
  editEtest,
  saveExtraTime
} from "@/api/admin/academic/eTest";
import moment from "moment/moment";
moment.locale("id");

export default {
  props: {
    open: Boolean,
    info: Object,
    view: Boolean,
    add: Boolean,
    id: Number,
    remedy: Boolean,
    continuation: Boolean,
    isPublish: Boolean
  },
  watch: {
    "bodyRequest.allClass.isAllClass"(value) {
      if (value) {
        this.bodyRequest.someClass = [];
        this.classList.map(d => {
          d.startDate = "";
          d.endDate = "";
          d.startHours = "";
          d.endHours = "";
        });
      }
    },
    async open(value) {
      if (value) {
        this.isEdit = this.view ? false : true;
        if (this.continuation) await this.getStudentScore();
        if (!this.remedy && !this.continuation) await this.getAllList();
        if ((!this.isEdit && !this.remedy) || this.continuation) {
          await this.getDataByID();
        }
        if (this.remedy && this.isEdit) {
          await this.getStudentScore();
          this.bodyRequest.title = this.info.header.etest_title.concat(
            " " + this.$i18n.t("e_test.remedy")
          );
          this.bodyRequest.type = this.info.header.type;
          this.bodyRequest.description = this.info.header.description;
        }
        if (!this.isEdit && this.remedy) {
          await this.getStudentScore();
          this.bodyRequest.title = this.info.header.title;
          this.bodyRequest.description = this.info.header.descriptionById;
          this.bodyRequest.duration = this.info.header.duration;
          this.bodyRequest.chance = this.info.header.chance;
          this.dateRemedy = this.info.header.dateRemedy;
        }
        this.stepPosition = 1;
        this.resetStepOne = false;
        this.resetStepTwo = false;
        this.resetStepThree = false;
        this.validateStepOne = false;
      }
    },
    steps(val) {
      if (this.stepPosition > val) {
        this.stepPosition = val;
      }
    },
    info(value) {
      if (!this.continuation) {
        this.bodyRequest.lesson = value.header.lesson;
      }
    },
    stepPosition() {
      this.showButtonEdit();
    }
  },
  components: {
    StepOne: () => import("./components/StepOne"),
    StepTwo: () => import("./components/StepTwo"),
    StepThree: () => import("./components/StepThree"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  data() {
    return {
      modelDialogConfirm: false,
      loadingConfirm: false,
      isEdit: false,
      dataStudents: this.objectDataStudent(),
      stepPosition: 1,
      loadingSave: false,
      resetStepOne: false,
      resetStepTwo: false,
      resetStepThree: false,
      validateStepOne: false,
      steps: 2,
      classOnlyView: [],
      classList: [],
      dateRemedy: {
        startDate: "",
        endDate: "",
        startHours: "",
        endHours: ""
      },
      bodyRequest: {
        is_private: true,
        students: [],
        allClass: {
          isAllClass: true,
          startDate: "",
          endDate: "",
          startHours: "",
          endHours: ""
        },
        someClass: [],
        lesson: "",
        type: "",
        title: "",
        duration: "",
        chance: "",
        description: "",
        schedule: []
      },
      bodyUpdateEtest: {},
      typeList:
        this.$store.getters.g_role_type === "TEACHER" ? "homeroom_teacher" : ""
    };
  },
  methods: {
    showButtonEdit() {
      return (
        this.view && !this.remedy && !this.continuation && this.isEditPublish()
      );
    },
    isEditPublish() {
      return this.isPublish ? this.stepPosition === 1 : true;
    },
    async getStudentScore() {
      this.dataStudents.loading = true;
      const etestClass = this.continuation
        ? this.info.etestClass
        : this.isEdit
        ? this.$route.params.etestClass
        : this.info.header.etest_class;
      const body = {
        ...this.dataStudents.request,
        etest_class: etestClass
      };
      try {
        let response;
        if (this.isEdit || this.continuation) {
          response = await listStudentScore(body);
        } else {
          response = await getStudent(body);
        }
        if (response.data.code) {
          this.dataStudents.dataTable = response.data.data.body;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, this.$i18n.t("app.there_is_an_error"));
        console.log("getStudentScore()\n", error);
        this.dataStudents.loading = false;
      }
      this.dataStudents.loading = false;
    },
    paginateStudent(item) {
      this.dataStudents.request.limit = item.limit;
      this.dataStudents.request.page = item.page;
      this.getStudentScore();
    },
    searchStudent(value) {
      this.dataStudents.request.search = value;
      this.getStudentScore();
    },
    selectAllStudent(all) {
      this.dataStudents.studentsModel = all
        ? this.dataStudents.dataTable.data
        : [];
    },
    changeFilterValue(value) {
      this.dataStudents.request.filter_score = value;
      this.getStudentScore();
    },
    async getDataByID() {
      this.classList = [];
      try {
        const response = await getQuestionByID({ id: this.id });
        if (response.data.code === 1) {
          if (this.continuation) {
            const data = response.data.data.body;
            this.dateRemedy = this.info;
            this.bodyRequest.title = data.title;
            this.bodyRequest.description = data.description;
            this.bodyRequest.duration = data.duration;
          } else {
            const allClass = {
              isAllClass: false,
              startDate: "",
              endDate: "",
              startHours: "",
              endHours: ""
            };

            const body = response.data.data.body;
            const getClass = body.schedule.map(r => r.class);
            const temp = [];

            this.classOnlyView.map(r => {
              getClass.map(d => {
                if (d == r.id) {
                  temp.push(r);
                }
              });
            });

            this.bodyRequest = {
              ...body,
              is_private: body.is_private === 1,
              allClass: allClass,
              someClass: temp
            };

            const classListTemp = [];
            this.classOnlyView.map(d => {
              let resultEpoch = {};
              const index = this.bodyRequest.schedule.findIndex(
                v => v.class === d.id
              );
              if (index >= 0) {
                const data = this.bodyRequest.schedule[index];
                const startDate = moment
                  .unix(parseInt(data.start.toString().substr(0, 10)))
                  .format("YYYY-M-DD");
                const endDate = moment
                  .unix(parseInt(data.end.toString().substr(0, 10)))
                  .format("YYYY-M-DD");
                const startHours = moment
                  .unix(parseInt(data.start.toString().substr(0, 10)))
                  .format("HH:mm");
                const endHours = moment
                  .unix(parseInt(data.end.toString().substr(0, 10)))
                  .format("HH:mm");

                resultEpoch = {
                  startDate: startDate,
                  endDate: endDate,
                  startHours: startHours,
                  endHours: endHours,
                  status: data.status
                };
              }

              classListTemp.push({
                ...d,
                ...resultEpoch
              });
            });

            this.classList = classListTemp;
          }
        } else {
          this.snackBar(false, response.data.code);
        }
      } catch (error) {
        this.snackBar(false, this.$i18n.t("app.there_is_an_error"));
        console.error("getDataByID()\n", error);
      }
    },
    async save() {
      this.loadingSave = true;
      if (this.remedy) {
        if (this.dataStudents.studentsModel.length === 0) {
          this.snackBar(false, this.$i18n.t("e_test.msg_remedi_create"));
        } else {
          const remedy = this.dateRemedy;
          const startDate = parseInt(
            moment(remedy.startDate.concat(" " + remedy.startHours)).valueOf()
          );
          const endDate = parseInt(
            moment(remedy.endDate.concat(" " + remedy.endHours)).valueOf()
          );
          this.bodyRequest.students = this.dataStudents.studentsModel.map(
            item => item.student
          );
          const body = {
            ...this.bodyRequest,
            etest_class: this.$route.params.etestClass,
            start: startDate,
            end: endDate
          };
          try {
            const response = await createRemedy(body);
            if (response.data.code) {
              this.snackBar(
                true,
                this.$i18n.t("e_test.msg.success_create_remedy")
              );
              this.closeDialog();
            } else {
              this.snackBar(false, response.data.message);
            }
          } catch (error) {
            this.snackBar(false, this.$i18n.t("app.there_is_an_error"));
            console.error("Create remedy\n", error);
            this.loadingSave = false;
          }
        }
      } else if (this.add) {
        const allClass = this.bodyRequest.allClass;
        this.bodyRequest.schedule = [];
        let error = { isError: false, msg: "" };
        if (!this.view) {
          if (allClass.isAllClass) {
            // validate if date and hours empty
            if (
              allClass.startHours &&
              allClass.endHours &&
              allClass.startDate &&
              allClass.endDate
            ) {
              if (this.validateDateTime(allClass)) {
                const startDate = moment(
                  allClass.startDate.concat(" " + allClass.startHours)
                ).valueOf();
                const endDate = moment(
                  allClass.endDate.concat(" " + allClass.endHours)
                ).valueOf();
                this.allList.map(d =>
                  this.bodyRequest.schedule.push({
                    class: d.id,
                    start: startDate,
                    end: endDate
                  })
                );
              } else {
                error.isError = true;
                error.msg = this.$i18n.t("e_test.msg_time");
              }
            } else {
              error.isError = true;
              error.msg = this.$i18n.t("e_test.msg_date_etest_required");
            }
          } else {
            this.classList.map(d => {
              if (d.isSelected) {
                // validate if date and hours empty
                if (
                  !d.startDate ||
                  !d.endDate ||
                  !d.startHours ||
                  !d.endHours
                ) {
                  error.isError = true;
                  error.msg = this.$i18n.t("e_test.msg_date_etest_required");
                }
                // validate if hours equal
                if (!this.validateDateTime(d)) {
                  error.isError = true;
                  error.msg = this.$i18n.t("e_test.msg_time");
                }
                this.bodyRequest.schedule.push({
                  class: d.id,
                  start: moment(
                    d.startDate.concat(" " + d.startHours)
                  ).valueOf(),
                  end: moment(d.endDate.concat(" " + d.endHours)).valueOf()
                });
              }
            });
          }

          if (error.isError) {
            this.snackBar(false, error.msg);
          } else {
            try {
              // handle if lesson empty
              if (!this.bodyRequest.lesson) {
                this.bodyRequest.lesson = this.$route.params.lesson;
              }
              const response = await createEtest(this.bodyRequest);
              if (response.data.code === 1) {
                this.snackBar(
                  true,
                  this.$i18n.t("e_test.msg_success_create_etest")
                );
                this.closeDialog();
              } else {
                this.snackBar(false, response.data.message);
              }
            } catch (error) {
              this.snackBar(false, this.$i18n.t("app.there_is_an_error"));
              console.error("Create E-Test\n", error);
              this.loadingSave = false;
            }
          }
        } else this.closeDialog();
      } else if (this.continuation) {
        // show dialog confirm
        this.modelDialogConfirm = true;
      } else {
        let data = JSON.parse(JSON.stringify(this.bodyRequest));
        let classList = JSON.parse(JSON.stringify(this.classList));
        const new_schedule = [];
        let error = { isError: false, msg: "" };
        const allClass = this.bodyRequest.allClass;
        // all class
        if (allClass.isAllClass) {
          if (
            allClass.startHours &&
            allClass.endHours &&
            allClass.startDate &&
            allClass.endDate
          ) {
            if (this.validateDateTime(allClass)) {
              const startDate = moment(
                allClass.startDate.concat(" " + allClass.startHours)
              ).valueOf();
              const endDate = moment(
                allClass.endDate.concat(" " + allClass.endHours)
              ).valueOf();
              classList.map(r =>
                new_schedule.push({
                  class: r.id,
                  start: startDate,
                  end: endDate
                })
              );
            } else {
              error.isError = true;
              error.msg = this.$i18n.t("e_test.msg_time");
            }
          } else {
            error.isError = true;
            error.msg = this.$i18n.t("e_test.msg_date_etest_required");
          }
        } else {
          // some class
          classList
            .filter(f => data.someClass.some(s => s.id === f.id))
            .map(r => {
              const startDate = parseInt(
                moment(r.startDate.concat(" " + r.startHours)).valueOf()
              );
              const endDate = parseInt(
                moment(r.endDate.concat(" " + r.endHours)).valueOf()
              );

              new_schedule.push({
                class: r.id,
                start: startDate,
                end: endDate
              });
            });
        }
        data.schedule = new_schedule;
        if (error.isError) {
          this.snackBar(false, error.msg);
        } else {
          this.bodyUpdateEtest = data;
          this.modelDialogConfirm = true;
        }
      }
      this.loadingSave = false;
    },
    async saveDialog() {
      this.loadingConfirm = true;
      if (this.continuation) {
        try {
          const body = {
            students: this.dataStudents.studentsModel.map(item => item.student),
            etest_class: this.info.etestClass,
            end: parseInt(
              moment(
                this.dateRemedy.endDate.concat(" " + this.dateRemedy.endHours)
              ).valueOf()
            ),
            duration: this.bodyRequest.duration,
            chance: this.bodyRequest.chance
          };
          const response = await saveExtraTime(body);
          if (response.data.code === 1) {
            this.snackBar(
              true,
              this.$i18n.t("e_test.msg.success_continuation")
            );
            this.closeDialog();
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, this.$i18n.t("app.there_is_an_error"));
          console.error("saveDialog()\n", error);
          this.loadingConfirm = false;
          this.modelDialogConfirm = false;
        }
      } else {
        try {
          const response = await editEtest(this.bodyUpdateEtest);
          if (response.data.code) {
            this.snackBar(true, this.$i18n.t("e_test.msg_update_etest"));
            this.closeDialog();
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          console.error("save()", error);
          this.snackBar(false, this.$i18n.t("app.there_is_an_error"));
          this.loadingConfirm = false;
          this.modelDialogConfirm = false;
        }
      }
      this.loadingConfirm = false;
      this.modelDialogConfirm = false;
    },
    validateDateTime(item) {
      let result = true;
      if (item.startDate === item.endDate) {
        if (item.startHours === item.endHours) {
          result = false;
        } else {
          result = true;
        }
      } else {
        result = true;
      }
      return result;
    },
    objectDataStudent() {
      return {
        studentsModel: [],
        request: {
          filter_score: "",
          limit: 1000,
          search: "",
          page: 1
        },
        loading: false,
        dataTable: { data: [] }
      };
    },
    reset() {
      this.dataStudents = this.objectDataStudent();
      this.isEdit = false;
      this.stepPosition = 1;
      this.resetStepOne = true;
      this.resetStepTwo = true;
      this.resetStepThree = true;
      this.dateRemedy = {
        startDate: "",
        endDate: "",
        startHours: "",
        endHours: ""
      };
      this.bodyRequest = {
        remedy: {
          startDate: "",
          endDate: ""
        },
        allClass: {
          isAllClass: true,
          startDate: "",
          endDate: "",
          startHours: "",
          endHours: ""
        },
        is_private: true,
        someClass: [],
        lesson: this.info.header.lesson,
        type: "",
        title: "",
        duration: "",
        chance: "",
        description: "",
        schedule: [],
        allList: []
      };
    },
    changeSelect(data) {
      this.bodyRequest.allClass.isAllClass = false;
      this.classList.find(d => d.id === data.item.id).isSelected = data.value;
    },
    async getAllList() {
      const headerInfo = this.info.header;
      try {
        const response = await get_class_list({
          grade: [headerInfo.grade],
          school_year: [headerInfo.school_year],
          type: this.typeList.toUpperCase()
        });
        if (response.data.code === 1) {
          response.data.data.map(d => {
            d.isSelected = false;
            d.startDate = "";
            d.endDate = "";
            d.startHours = "";
            d.endHours = "";
          });
          if (this.view) this.classOnlyView = response.data.data;
          else {
            this.classList = response.data.data;
            this.allList = response.data.data;
          }
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, this.$i18n.t("app.there_is_an_error"));
        console.error("getAllList()\n", error);
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    closeDialog() {
      this.reset();
      this.$emit("close");
    },
    nextStep(n) {
      if (n === this.steps) {
        this.stepPosition = 1;
      } else {
        if (this.isEdit) {
          switch (n) {
            case 1:
              if (
                (this.bodyRequest.lesson &&
                  this.bodyRequest.type &&
                  this.bodyRequest.title &&
                  this.bodyRequest.duration &&
                  this.bodyRequest.duration >= 1 &&
                  this.bodyRequest.chance &&
                  this.bodyRequest.chance >= 1 &&
                  this.bodyRequest.description &&
                  this.validateDateRemedy()) ||
                this.validateDateEdit()
              ) {
                this.stepPosition = n + 1;
                this.validateStepOne = false;
                break;
              } else {
                this.validateStepOne = true;
                break;
              }
          }
        } else this.stepPosition = n + 1;
      }
    },
    validateDateRemedy() {
      const remedy = this.dateRemedy;
      const validate =
        remedy.startDate &&
        remedy.endDate &&
        remedy.startHours &&
        remedy.endHours;
      return this.remedy || this.continuation ? validate : true;
    },
    validateDateEdit() {
      const stepOne = this.bodyRequest;
      const validate =
        stepOne.title &&
        stepOne.chance &&
        stepOne.chance >= 1 &&
        stepOne.description;
      const validateContinuation = this.validateDateRemedy() && validate;
      return this.continuation
        ? validateContinuation
        : this.isEdit
        ? validate
        : true;
    },
    prevStep(n) {
      if (n === 1) {
        this.stepPosition = 1;
      } else {
        this.stepPosition = n - 1;
      }
      this.$vuetify.goTo(150);
    }
  }
};
</script>
